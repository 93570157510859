<template>
  <control-panel-section :header="'SIMULATION'">
    <b-container class="p-0">
      <b-form-row>
        <b-col>
          <b-form-group label="Cutoff" label-class="input-group-title"
                        :disabled="$route.name === 'published'">
            <b-form-input
              class="themed-input"
              v-model="cutoff"
              v-on:change="cutoffChanged"
              size="sm"
              type="number"
              :value="projectCutoff"
              min="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="HBar" label-class="input-group-title"
                        :disabled="$route.name === 'published'">
            <b-form-input
              class="themed-input"
              v-model="hbar"
              v-on:change="hbarChanged"
              size="sm"
              type="number"
              step="0.1"
              :value="projectHbar"
              min="0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group label="Measurement cutoff" label-class="input-group-title"
                        :disabled="$route.name === 'published'">
            <b-form-input
              class="themed-input"
              v-model="measurement_cutoff"
              v-on:change="measurementCutoffChanged"
              size="sm"
              type="number"
              :value="projectMeasurementCutoff"
              min="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Shots" label-class="input-group-title"
                        :disabled="$route.name === 'published'">
            <b-form-input
              class="themed-input"
              v-model="shots"
              v-on:change="shotsChanged"
              size="sm"
              type="number"
              min="1"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-container>
  </control-panel-section>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  CHANGE_CUTOFF,
  CHANGE_HBAR,
  CHANGE_MEASUREMENT_CUTOFF,
  CHANGE_SHOTS,
} from '@/store/mutations.type'
import ControlPanelSection from './control-panel-section'

export default {
  name: 'control-panel-section-simulation',
  components: {
    ControlPanelSection,
  },
  data() {
    return {
      cutoff: 5,
      hbar: 2,
      measurement_cutoff: 5,
      shots: 1,
    }
  },
  mounted() {
    this.cutoff = this.projectCutoff
    this.hbar = this.projectHbar
    this.measurement_cutoff = this.projectMeasurementCutoff
    this.shots = this.projectShots
  },
  methods: {
    cutoffChanged(cutoff) {
      this.$store.commit(CHANGE_CUTOFF, parseInt(cutoff))
    },
    hbarChanged(hbar) {
      this.$store.commit(CHANGE_HBAR, parseInt(hbar))
    },
    measurementCutoffChanged(measurement_cutoff) {
      this.$store.commit(
        CHANGE_MEASUREMENT_CUTOFF,
        parseInt(measurement_cutoff)
      )
    },
    shotsChanged(shots) {
      this.$store.commit(CHANGE_SHOTS, parseInt(shots))
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getProjectById']),
    projectCutoff() {
      return this.getProjectById(this.$route.params.projectId)?.projectData
        ?.config?.cutoff
    },
    projectHbar() {
      return this.getProjectById(this.$route.params.projectId)?.projectData
        ?.config?.hbar
    },
    projectMeasurementCutoff() {
      return this.getProjectById(this.$route.params.projectId)?.projectData
        ?.config?.measurement_cutoff
    },
    projectShots() {
      return this.getProjectById(this.$route.params.projectId)?.projectData
        ?.shots
    },
  },
}
</script>

<style scoped>
.themed-input {
  color: #98a1ae;
  font-size: 0.85rem;
  background-color: var(--pq-blue-shade3);
  border: none;
}

.themed-input[disabled="disabled"] {
  color: var(--pq-font-color-shade4);
  background-color: var(--pq-blue-shade3);
  filter: brightness(0.95);
}
</style>
