<template>
  <div>
    <p class="mb-3">Project name</p>
    <b-form-input
      label="Project name"
      v-model="projectName"
      placeholder="Project name.."
      class="modal-input-text form-control form-control-sm mb-4"
    />
    <b-form-group label="Select a scheme" class="scheme-radio">
      <b-form-radio-group
        v-model="selectedScheme"
        :options="schemes"
        class="modal-input"
        buttons
        stacked
      />
    </b-form-group>
    <div class="modal-footer">
      <b-button
        style="float: right"
        class="mt-3 modal-close-ok-btn"
        @click="newProject"
      >
        Create Project
      </b-button>
    </div>
  </div>
</template>

<script>
import { CREATE_PROJECT, MOCK_CREATE_PROJECT } from '@/store/actions.type'
import { Scheme } from '@budapest-quantum-computing-group/piquasso-composer/src/index'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateNewProjectModal',
  data: function () {
    return {
      projectName: 'New Project',
      schemes: Object.values(Scheme).map((s) => ({ text: s, value: s })),
      selectedScheme: Object.values(Scheme)[0],
      showModal: false,
    }
  },
  methods: {
    async newProject() {
      let projectId = ''
      if (this.isAuthenticated) {
        projectId = await this.$store.dispatch(CREATE_PROJECT, {
          name: this.projectName,
          scheme: this.selectedScheme,
        })

      } else {
        projectId = await this.$store.dispatch(MOCK_CREATE_PROJECT, {
          name: this.projectName,
          scheme: this.selectedScheme,
        })
      }

      this.$emit('onClose')

      // Redirect to composer page with the circuit ID in the url
      await this.$router.push({
        name: 'composer',
        params: { projectId },
      })
    },
  },
  computed: {
    ...mapGetters([
      'isAuthenticated'
    ]),
  },
}
</script>

<style>
.modal-input-text,
.modal-input-text:focus {
  width: 100%;
  color: var(--pq-font-color-shade2);
  font-size: 0.9rem;
  padding: 1.25rem 1rem;
  background-color: var(--pq-blue-shade2);
  border: none;
  box-shadow: none !important;
}

.modal-input {
  width: 100%;
  color: var(--pq-font-color-shade2);
  padding: 0.25rem 0;
  background-color: var(--pq-blue-shade1);
  border: none;
  box-shadow: none !important;
}

.modal-input .btn-secondary {
  color: var(--pq-font-color-shade3) !important;
  font-size: 0.9rem;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0.75rem 1rem;
}

.modal-input .btn-secondary:hover {
  color: var(--pq-font-color-shade2) !important;
  background-color: var(--pq-color-border) !important;
}

.modal-input .btn-secondary:focus,
.modal-input .btn-secondary.active,
.modal-input .btn-secondary:focus-visible,
.modal-input .focus,
.modal-input .active {
  color: var(--pq-font-color-shade2) !important;
  font-weight: 500;
  background-color: var(--pq-blue-shade2) !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
