<template>
  <b-container class="control-panel">
    <control-panel-section-control />
    <control-panel-section-simulation />
    <control-panel-section-input-params />
    <control-panel-section-documentation />
  </b-container>
</template>

<script>
import ControlPanelSectionControl from './control-panel-section-control'
import ControlPanelSectionSimulation from './control-panel-section-simulation'
import ControlPanelSectionDocumentation from '@/components/controlpanel/control-panel-section-documentation'
import ControlPanelSectionInputParams from '@/components/controlpanel/control-panel-section-input-params'

export default {
  name: 'control-panel',
  components: {
    ControlPanelSectionInputParams,
    ControlPanelSectionDocumentation,
    ControlPanelSectionControl,
    ControlPanelSectionSimulation,
  },
  computed: {
    currentDoc: function () {
      return this.$store.getters.getDocumentation
    },
  },
}
</script>

<style>
.control-panel {
  font-size: 0.75rem;
}
.input-field {
  font-size: 0.7rem;
  color: #98a1ae;
}
.input-group-title {
  color: var(--pq-font-color-shade1);
}
</style>
