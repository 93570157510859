<template>
  <div>
    <PublishModal
      v-if="showPublishModal"
      :id="this.getCurrentProject.id"
      v-on:published="showPublishModal = false"
    />
    <div class="d-flex flex-row project-navbar">
      <b-navbar>
        <b-navbar-nav>
          <b-dropdown text="File" class="menu-item" no-caret>
            <b-dropdown-item @click="createNewProject"
              >New Project</b-dropdown-item
            >
            <b-dropdown-item :disabled="!isAuthenticated" @click="openProject"
              >Open..</b-dropdown-item
            >
            <b-dropdown-item :disabled="!isAuthenticated|| $route.name === 'published'" @click="saveProject"
              >Save</b-dropdown-item
            >
            <b-dropdown-item :disabled="!isAuthenticated || $route.name === 'published'" @click="renameProject"
              >Rename</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="closeCurrentProject"
              >Close</b-dropdown-item
            >
          </b-dropdown>
          <b-nav-item href="#" :disabled="!isAuthenticated|| $route.name === 'published'">Share</b-nav-item>
          <b-nav-item href="#">Help</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <div class="ml-2 d-flex align-items-center">
        <button
          v-if="$route.name === 'composer'"
          class="publish-btn"
          :disabled="!isAuthenticated"
          @click="showPublishModal = true"
        >
          Publish
        </button>
        <button
          v-if="$route.name === 'published'"
          class="publish-btn"
          @click="makeCopy"
          :disabled="!isAuthenticated"
        >
          Make a copy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ModalBus } from '@/common/eventBus'
import { mapGetters } from 'vuex'
import {
  SAVE_PROJECT,
  CLOSE_PROJECT,
  MAKE_COPY_FROM_PUBLISHED_PROJECT,
} from '@/store/actions.type'
import {
  ADD_TOAST_MESSAGE,
  SET_DEACTIVATED_PROJECT,
} from '@/store/mutations.type'
import CreateNewProjectModal from '@/components/modals/create-new-project-modal'
import RenameProjectModal from '@/components/modals/rename-project-modal'
import PublishModal from '@/components/modals/publish-modal'

export default Vue.extend({
  name: 'NavBar',
  components: {
    PublishModal,
  },
  data() {
    return {
      showPublishModal: false,
    }
  },
  methods: {
    createNewProject() {
      ModalBus.$emit('open', {
        component: CreateNewProjectModal,
        title: 'New Project',
      })
    },

    openProject() {
      this.$store.commit(SET_DEACTIVATED_PROJECT)
      this.$router.push({ name: 'dashboard-projects' })
    },

    saveProject() {
      this.$store.dispatch(SAVE_PROJECT, this.$route.params.projectId)
      this.$store.commit(ADD_TOAST_MESSAGE, {
        message: 'Project saved',
        type: 'success',
      })
    },

    renameProject() {
      ModalBus.$emit('open', {
        component: RenameProjectModal,
        title: 'Rename Project',
        props: {
          projectName: this.getCurrentProject.name,
          ProjectId: this.getCurrentProject.id,
        },
      })
    },

    closeCurrentProject() {
      this.$store.dispatch(CLOSE_PROJECT, this.getCurrentProject.id)
      if (this.getOpenedProjects.length === 0) {
        this.$router.push({ name: 'dashboard-home' })
      } else {
        this.$router.push({
          name: 'composer',
          params: { projectId: this.getCurrentProject.id },
        })
      }
    },

    async makeCopy() {
      const resp = await this.$store.dispatch(
        MAKE_COPY_FROM_PUBLISHED_PROJECT,
        this.$route.params.projectId
      )
      if (resp.id) {
        await this.$router.push({
          name: 'composer',
          params: {
            projectId: resp.id,
          },
        })
      }
    },
    on_enter_handler(event, fn) {
      if (event.which === 13) {
        fn()
      }
    },
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'getCurrentProject',
      'getOpenedProjects',
    ]),
  },
})
</script>

<style scoped>
a {
  outline: 0;
}

.project-navbar {
  background-color: var(--pq-blue-shade2);
  border-bottom: 1px solid var(--pq-color-border);
  padding-right: 30px;
}

.publish-btn {
  height: 80%;
  display: flex;
  align-items: center;
  color: var(--pq-color-red);
  font-size: 0.8rem;
  padding: 0 1rem;
  border: 1px solid var(--pq-color-red);
  border-radius: 0.5rem;
  background-color: transparent;
}

.publish-btn[disabled="disabled"] {
  opacity: 0.75;
  color: var(--pq-font-color-shade4) !important;
  border: 1px solid var(--pq-font-color-shade4) !important;
}

.navbar {
  position: relative;
  padding: 0;
}

.menu-item {
  height: 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-link {
  color: var(--pq-font-color-shade2) !important;
  padding-left: 0.9rem !important;
  padding-right: 0.9rem !important;
}

.nav-link.disabled {
  color: var(--pq-font-color-shade4) !important;
}

.nav-link:hover,
.nav-link:focus {
  color: white !important;
  background-color: var(--pq-blue-shade3);
}

.show-projects-window {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  color: #ccc;
  transform: translate(-50%, -50%);
  background-color: #232c38;
  z-index: 999;
  border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-overlay {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  cursor: pointer;
}

.scheme-radio >>> .btn {
  border: 0;
  background-color: unset;
  width: 100%;
}

.scheme-radio >>> .btn.active {
  background-color: var(--pq-blue-shade4) !important;
}
</style>
