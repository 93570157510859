<template>
  <div>
    <p class="mb-3">New project name</p>
    <b-form-input
      label="New project name"
      v-model="newProjectName"
      placeholder="New project name.."
      class="modal-input-text form-control form-control-sm mb-4"
    />

    <div class="modal-footer">
      <b-button
        style="float: right"
        class="mt-3 modal-close-ok-btn"
        @click="renameProject"
      >
        Rename
      </b-button>
      <b-button
        style="float: right"
        class="mt-3 modal-close-cancel-btn"
        @click="$emit('onClose')"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { RENAME_PROJECT } from '@/store/actions.type'

export default {
  name: 'RenameProjectModal',
  props: ['projectName', 'ProjectId'],
  data() {
    return {
      newProjectName: this.projectName,
    }
  },
  methods: {
    renameProject() {
      const payload = {
        projectId: this.ProjectId,
        newProjectName: this.newProjectName,
      }
      this.$store.dispatch(RENAME_PROJECT, payload)
      this.$emit('onCloseAndReturn')
    },
  },
}
</script>

<style scoped></style>
