<template>
  <div class="h-100 w-100">
    <nav-bar ref="navBar" />
    <Composer />
  </div>
</template>

<script>
import Vue from 'vue'
import NavBar from '@/components/nav-bar.vue'
import Composer from '@/components/composer/Composer.vue'

export default Vue.extend({
  name: 'ComposerPage',
  components: {
    NavBar,
    Composer,
  },
  mounted() {
    document.title = 'Composer | Piquasso'
  },
})
</script>
