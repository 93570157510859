<template>
  <control-panel-section v-if="inputNeeded" header="PARAMETERS">
    <b-container class="p-0">
      <b-form-row v-for="param in currentParams" :key="param.opId + param.key">
        <b-col>
          <b-form-group :label="param.key" label-class="input-group-title">
            <b-form-input
              size="sm"
              type="text"
              class="themed-input"
              :class="validityClass.get(param.opId + param.key)"
              v-model="params[param.opId + param.key]"
              @input="() => validate(param.opId + param.key)"
              @change="(val) => paramChanged(param.key, val)"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-container>
  </control-panel-section>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_PARAM } from '@/store/mutations.type'
import { Map } from 'immutable'
import { evaluate } from 'mathjs'
import ControlPanelSection from './control-panel-section'

export default {
  name: 'control-panel-section-input-params',
  components: {
    ControlPanelSection,
  },
  data() {
    return {
      params: {},
      validityClass: Map(),
    }
  },
  computed: {
    ...mapGetters(['getParams']),
    currentParams: function () {
      return this.getParams
    },

    inputNeeded: function () {
      return this.getParams !== undefined
    },

    inputIsValid: function (key) {
      try {
        return !isNaN(parseInt(evaluate(this.params[key])))
      } catch (e) {
        return false
      }
    },
  },
  methods: {
    paramChanged: function (key, value) {
      try {
        const parsedValue = JSON.stringify(evaluate(value))
        this.$store.commit(SET_PARAM, [key, [value, parsedValue]])
      } catch (e) {
        // TODO: bad input
      }
    },
    validate: function (key) {
      try {
        JSON.stringify(evaluate(this.params[key]))
        this.validityClass = this.validityClass.set(key, '')
      } catch (e) {
        this.validityClass = this.validityClass.set(key, 'is-invalid')
      }
    },
  },
  watch: {
    currentParams: function () {
      this.params = {}
      this.validityClass = Map()
      if (this.currentParams) {
        for (const param of this.currentParams) {
          this.params[param.opId + param.key] = param.value
            ? param.value[0]
              ? param.value[0]
              : param.value
            : 0
          this.validityClass = this.validityClass.set(
            param.opId + param.key,
            ''
          )
        }
      }
    },
  },
}
</script>

<style scoped>
.themed-input {
  color: #98a1ae;
  font-size: 0.85rem;
  background-color: var(--pq-blue-shade3);
  border: none;
}

.is-invalid {
  /*background-color: red;*/
}
</style>
