<template>
  <control-panel-section header="CONTROL">
    <div class="d-flex justify-content-between" style="width: 100%">
      <b-form-group label="Run" label-class="input-group-title">
        <b-button @click="run()" size="sm" class="cp-button">
          <b-icon-play-fill class="btn-run text-center" font-scale="2" />
        </b-button>
      </b-form-group>
      <b-form-group label="Stop" label-class="input-group-title">
        <b-button @click="stop()" size="sm" class="cp-button">
          <b-icon-stop-fill class="btn-stop text-center" font-scale="2" />
        </b-button>
      </b-form-group>
      <b-form-group label="Undo" label-class="input-group-title" :disabled="$route.name === 'published'">
        <b-button @click="undo()" size="sm" class="cp-button">
          <b-icon-arrow-left-short
            class="btn-undo text-center"
            font-scale="2"
          />
        </b-button>
      </b-form-group>
      <b-form-group label="Redo" label-class="input-group-title" :disabled="$route.name === 'published'">
        <b-button @click="redo()" size="sm" class="cp-button">
          <b-icon-arrow-right-short
            class="btn-redo text-center"
            font-scale="2"
          />
        </b-button>
      </b-form-group>
    </div>
  </control-panel-section>
</template>

<script>
import ControlPanelSection from './control-panel-section'
import {
  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconPlayFill,
  BIconStopFill,
} from 'bootstrap-vue'
import { RUN, STOP, UNDO, REDO } from '@/store/actions.type'

export default {
  name: 'control-panel-section-control',
  components: {
    ControlPanelSection,
    BIconPlayFill,
    BIconStopFill,
    BIconArrowLeftShort,
    BIconArrowRightShort,
  },
  methods: {
    run() {
      this.$store.dispatch(RUN)
    },
    stop() {
      this.$store.dispatch(STOP)
    },
    undo() {
      this.$store.dispatch(UNDO)
    },
    redo() {
      this.$store.dispatch(REDO)
    },
  },
}
</script>

<style>
.btn-run {
  color: #27ae60 !important;
}

.btn-stop {
  color: #ed7171 !important;
}

.btn-undo {
  color: #2d9cdb !important;
}

.btn-redo {
  color: #2d9cdb !important;
}

.cp-button {
  text-align: center;
  border: 0 !important;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 0.75rem;
}

.cp-button:active {
  transform: scale(1.1);
}

.cp-section-body .form-group[disabled="disabled"] > legend {
  opacity: 0.5;
}
</style>
