<template>
  <div class="p-modal__backdrop" @click="$emit('published')">
    <div id="publish-modal" key="modal" @click.stop>
      <div class="modal-title mb-5">
        <h4 class="mb-3" style="color: #fff">
          Publish your project in the Piquasso community!
        </h4>
        <p>
          Share your work with people in our community where they can discover,
          interact and experiment with your circuit.
        </p>
        <p>
          If you want to share it with people outside our community, you can use
          the automatically generated share-link after publishing to send it to
          your friends, collegues or reference it in a research paper.
        </p>
      </div>
      <div class="publish-inputs mb-4">
        <b-form>
          <b-form-group
            id="fieldset-1"
            class="mb-4"
            label="Project name"
            label-for="project-name"
            required
            :state="stateName"
          >
            <b-form-input
              id="project-name"
              v-model="projectName"
              :state="stateName"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Project Details"
            label-for="project-details"
            required
            :state="stateDetails"
          >
            <b-form-textarea
              id="project-details"
              v-model="projectDetails"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              :state="stateDetails"
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>
      </div>
      <section class="row project-thumbnail">
        <div class="col-sm-12 col-xl-10 overflow-hidden">
          <div :id="this.id"></div>
        </div>
      </section>
      <button class="btn" @click="publishProject">Publish Circuit</button>
    </div>
  </div>
</template>

<script>
import { PUBLISH_PROJECT } from '@/store/actions.type'
export default {
  name: 'PublishModal',
  props: ['id'],
  data() {
    return {
      projectName: '',
      projectDetails: '',
      changed: false,
    }
  },
  methods: {
    publishProject() {
      if (!(this.stateName && this.stateDetails)) {
        return
      }
      const payload = {
        ProjectId: this.$route.params.projectId,
        title: this.projectName,
        description: this.projectDetails,
      }
      this.$store.dispatch(PUBLISH_PROJECT, payload)
      this.$emit('published')
    },
  },
  computed: {
    stateName() {
      if (this.projectName) {
        return this.projectName.length >= 4 || !this.changed
      }
      return null
    },
    stateDetails() {
      if (this.projectDetails) {
        return this.projectDetails.length >= 5 || !this.changed
      }
      return null
    },
  },
  watch: {
    projectName() {
      this.changed = true
    },
    projectDetails() {
      this.changed = true
    },
  },
}
</script>

<style scoped>
.p-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;

  /* Makes animation laggy as hell */
  /* backdrop-filter: blur(1px); */
}

#publish-modal .modal-content {
  color: var(--pq-font-color-shade3);
  background-color: var(--pq-blue-shade2);
}

#publish-modal .modal-content footer {
  display: none;
}

#publish-modal .modal-content header h5 {
  color: var(--pq-font-color-shade1);
}

#publish-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: 100%;
  max-width: 50%;
  min-width: 400px;
  color: var(--pq-font-color-shade3);
  background-color: var(--pq-blue-shade2);
  padding: 2rem;
  box-shadow: 0 -5px 15px 3px rgb(0 0 0 / 45%);
  z-index: 1000;
}

.form-group input,
.form-group textarea {
  border: 1px solid var(--pq-color-border-dark);
}

.form-group label {
  color: var(--pq-font-color-shade1);
}

#project-name,
#project-details {
  max-width: 500px;
  color: var(--pq-font-color-shade1);
  background-color: #242e48;
}

.btn {
  color: #fff;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--pq-color-red);
}
</style>
