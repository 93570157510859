<template>
  <b-row class="cp-section mx-0">
    <div class="cp-section-header">
      {{ header }}
    </div>
    <div class="cp-section-body">
      <slot></slot>
    </div>
  </b-row>
</template>

<script>
export default {
  name: 'control-panel-section',
  props: {
    header: {
      type: String,
    },
  },
}
</script>

<style scoped>
.cp-section {
  margin-bottom: 0.5rem;
}

.cp-section-header {
  color: var(--pq-font-color-shade3);
  font-size: 0.65rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.cp-section-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
</style>
