<template>
  <control-panel-section header="DOCUMENTATION">
    <div
      v-katex:auto="{ strict: false }"
      v-html="currentDoc"
      style="color: rgb(220, 230, 244)"
    ></div>
  </control-panel-section>
</template>

<script>
import ControlPanelSection from './control-panel-section'

export default {
  name: 'control-panel-section-documentation',
  components: {
    ControlPanelSection,
  },
  computed: {
    currentDoc: function () {
      return this.$store.getters.getDocumentation
    },
  },
}
</script>

<!--<style scoped>-->
<!--</style>-->
