<template>
  <splitpanes class="columns content" style="overflow: hidden">
    <pane size="10" class="control-panel-column">
      <control-panel class="py-3" v-if="finishedLoading"/>
    </pane>
    <pane class="inner">
      <div id="composer-container" v-if="finishedLoading"/>
      <div v-if="showPendingJob">
        <!-- Needs loading animation
        <p>The job is pending...</p>
        -->
      </div>
      <results-area
        v-if="showResults"
        :result="getResults"
        class="rows mb-4"
        style="padding-top: 15px; padding-bottom: 10rem;"
      />
    </pane>
  </splitpanes>
</template>

<script>
import Vue from 'vue'
import ControlPanel from '@/components/controlpanel/control-panel.vue'
import ResultsArea from '@/components/resultsarea/results-area'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import {mapGetters} from 'vuex'
import {ModalBus} from '@/common/eventBus'
import {
  LOAD_PROJECT,
  ACTIVATE_PROJECT
} from '@/store/actions.type'
import {Scheme} from '@budapest-quantum-computing-group/piquasso-composer'
import CreateNewProjectModal from '@/components/modals/create-new-project-modal'
import router from "@/router";

export default Vue.extend({
  name: 'Composer',
  data() {
    return {
      finishedLoading: true,
      selectedScheme: Object.values(Scheme)[0],
      projectName: '',
    }
  },
  components: {
    ResultsArea,
    ControlPanel,
    Splitpanes,
    Pane,
  },
  mounted() {
    this.renderComposer()
  },
  methods: {
    renderComposer() {
      if (this.$route.params.projectId) {
        this.finishedLoading = false
        this.$store
          .dispatch(LOAD_PROJECT, this.$route.params.projectId)
          .then(() => {
            this.finishedLoading = true
            this.$store.dispatch(
              ACTIVATE_PROJECT,
              this.$route.params.projectId
            )
          })
        .catch((err) => {
          router.push({name: 'dashboard-home'})
        })
      } else {
        ModalBus.$emit('open', {
          component: CreateNewProjectModal,
          title: 'Create a new project!',
          closeOnClick: false
        })
      }
    },
  },
  computed: {
    ...mapGetters(['getCurrentProject', 'getResults', 'getLoadedProjects']),
    showPendingJob() {
      return this.getCurrentProject?.isSubmitted
    },
    showResults() {
      return this.getResults !== undefined
    },
  },
  watch: {
    $route: function () {
      this.renderComposer()
    },
  },
})
</script>

<style scoped>
.columns {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: scroll;
  height: 100%;
}

.control-panel-column {
  border-right: 1px solid var(--pq-color-border);
  width: 307px;
  min-width: 307px !important;
  max-width: 550px;
  overflow-x: hidden;
  overflow-y: auto;
}

#composer-container {
  width: 100%;
  border-bottom: 1px solid var(--pq-color-border);
}

.inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #242f4b;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 200px;
}
</style>
